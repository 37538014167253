import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import { useMutation } from 'react-query';
import { useAccount } from 'wagmi';

import { BridgeToken, useConfig } from '../../contexts/ConfigContext';
import { addDeposit } from '../../core/storage/deposit';
import { Side } from '../../core/type';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { useApprove } from '../../hooks/useApprove';
import { useReservedParamsNavigate } from '../../hooks/useReservedParamsNavigate';
import { usePublicL1Client } from '../../hooks/useStaticProvider';
import { useL1WalletClient } from '../../hooks/useWalletClient';

const DepositERC20Button: React.FC<{ amount: bigint; token: BridgeToken; valid?: boolean }> = ({
  amount,
  token,
  valid,
}) => {
  const { address } = useAccount();
  const {
    l1: { L1StandardBridgeProxy },
  } = useConfig();
  const navigate = useReservedParamsNavigate();
  const { alertSubmitted, alertConfirmed, alertFailed } = useAlertTransaction(Side.l1);
  const {
    approve,
    isApproved,
    isPending: isApprovePending,
  } = useApprove(token.address, address, L1StandardBridgeProxy, amount, 'l1');
  const walletClient = useL1WalletClient();
  const publicClient = usePublicL1Client();

  const { isLoading, mutate: depositERC20 } = useMutation<string, any, void>({
    mutationFn: async () => {
      if (!address) {
        throw new Error('arguments missed, address:', address);
      }

      const hash = await walletClient.writeContract({
        address: L1StandardBridgeProxy,
        abi,
        functionName: 'depositERC20',
        args: [token.address, token.oppositeToken.address, amount, BigInt('21000'), '0x'],
        account: address,
      });

      addDeposit({
        amountOrTokenId: amount,
        tokenAddress: token.address,
        account: address,
        transactionHash: hash,
      });
      alertSubmitted(hash);
      await publicClient.waitForTransactionReceipt({ hash });

      return hash;
    },
    onSuccess(hash) {
      alertConfirmed(hash);
      navigate(`/deposit/progress/${hash}`, { id: true });
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  if (!isApproved && valid) {
    return (
      <LoadingButton
        fullWidth
        loading={isApprovePending}
        onClick={() => approve()}
        variant="contained"
      >
        Approve
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      disabled={!valid}
      fullWidth
      loading={isLoading}
      onClick={() => depositERC20()}
      variant="contained"
    >
      Deposit
    </LoadingButton>
  );
};

export default DepositERC20Button;

const abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_l1Token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_l2Token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
      {
        internalType: 'uint32',
        name: '_minGasLimit',
        type: 'uint32',
      },
      {
        internalType: 'bytes',
        name: '_extraData',
        type: 'bytes',
      },
    ],
    name: 'depositERC20',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
