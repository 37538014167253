import { UseMutateAsyncFunction, useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Address } from 'viem';
import { useReadContract, useWriteContract } from 'wagmi';

import ERC20 from '../core/abis/ERC20.json';
import { Side } from '../core/type';
import useAlertTransaction from './useAlertTransaction';
import { usePublicClient } from './useStaticProvider';

interface UseApprove {
  allowance: bigint;
  isApproved: boolean;
  isPending: boolean;
  approve: UseMutateAsyncFunction<string, any, void, unknown>;
}

export const useApprove = (
  address: Address,
  owner: string | undefined,
  spender: string,
  amount: bigint,
  side: 'l1' | 'l2'
): UseApprove => {
  const client = usePublicClient(side);
  const { alertConfirmed, alertFailed } = useAlertTransaction(Side.l1);
  const { writeContractAsync } = useWriteContract();
  const { data, refetch } = useReadContract({
    address,
    abi: ERC20,
    functionName: 'allowance',
    args: [owner, spender],
  });

  const approve = useCallback(async () => {
    const hash = await writeContractAsync({
      abi: ERC20,
      address,
      functionName: 'approve',
      args: [spender, amount],
    });

    await client.waitForTransactionReceipt({ hash });
    await refetch();

    return hash;
  }, [address, amount, client, refetch, spender, writeContractAsync]);

  const { isPending, mutateAsync: approveMutate } = useMutation<string, any, void>({
    mutationFn: approve,
    onSuccess: alertConfirmed,
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  return {
    isPending,
    allowance: data as bigint,
    isApproved: (data as bigint) >= amount,
    approve: approveMutate,
  };
};
