import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import IconNotFound from '../../components/icons/IconNotFound';
import ProgressStepper from '../../components/ProgressStepper';
import TransferAmount from '../../components/TransferAmount';
import TransferDirection from '../../components/TransferDirection';
import { useConfig } from '../../contexts/ConfigContext';
import { Side } from '../../core/type';
import { useWithdrawInLS } from '../../hooks/useWithdrawInLS';
import { TxnHistoryTab } from '../TransactionHistory';
import { WithdrawalStatus, useWithdrawalProgress } from './WithdrawalProgressContext';
import { Unknown } from './status/Unknown';
import { StateNotPublished } from './status/StateNotPublished';
import { ReadyToProve } from './status/ReadyToProve';
import { InChallengePeriod } from './status/InChallengePeriod';
import { ReadyToFinalize } from './status/ReadyToFinalize';
import { Finalized } from './status/Finalized';

const steps = [
  WithdrawalStatus.STATE_ROOT_NOT_PUBLISHED,
  WithdrawalStatus.READY_TO_PROVE,
  WithdrawalStatus.IN_CHALLENGE_PERIOD,
  WithdrawalStatus.READY_FOR_RELAY,
  WithdrawalStatus.RELAYED,
];

const WithdrawalProgress: React.FC = () => {
  const { status } = useWithdrawalProgress();
  const { transactionHash } = useParams<{ transactionHash: `0x${string}` }>();
  const withdrawal = useWithdrawInLS(transactionHash);
  const theme = useTheme();
  const { l2Tokens } = useConfig();
  const token = useMemo(
    () => l2Tokens.find(token => token.address === withdrawal?.tokenAddress),
    [l2Tokens, withdrawal?.tokenAddress]
  );
  const activeStep = steps.findIndex(cur => cur === status);

  const content = useMemo(() => {
    if (!withdrawal) {
      return (
        <>
          <IconNotFound
            sx={{
              width: '48px',
              height: 'auto',
            }}
          />
          <Typography mt={2.5} variant="body2">
            Sorry, no transaction details were found by giving transaction hash
          </Typography>
        </>
      );
    }

    switch (status) {
      case WithdrawalStatus.UNKNOWN:
        return <Unknown />;
      case WithdrawalStatus.STATE_ROOT_NOT_PUBLISHED:
        return <StateNotPublished />;
      case WithdrawalStatus.READY_TO_PROVE:
        return <ReadyToProve transactionHash={transactionHash} />
      case WithdrawalStatus.IN_CHALLENGE_PERIOD:
        return <InChallengePeriod />;
      case WithdrawalStatus.READY_FOR_RELAY:
        return <ReadyToFinalize transactionHash={transactionHash} />
      case WithdrawalStatus.RELAYED:
        return <Finalized />;
    }
  }, [status, transactionHash, withdrawal]);

  return (
    <Box>
      <Breadcrumbs
        links={[
          { href: '/', name: 'home' },
          {
            href: '/transactions',
            name: 'transactions',
            params: {
              type: TxnHistoryTab.Withdrawal,
            },
          },
          { name: 'withdrawal progress' },
        ]}
      />
      <Container
        sx={{
          background: theme.colors.functional.container.default,
          borderRadius: '4px',
          py: 3,
          my: 1,
        }}
      >
        <Typography variant="h5">Withdrawal progress</Typography>
        <TransferDirection from={Side.l2} />
        {token && withdrawal && (
          <TransferAmount
            amount={BigInt(withdrawal.amountOrTokenId)}
            from={Side.l2}
            mb={5}
            token={token}
          />
        )}
        {status !== WithdrawalStatus.UNKNOWN && (
          <ProgressStepper activeStep={activeStep} steps={steps} />
        )}
        <Stack alignItems="center" justifyContent="center" minHeight="10rem" mt={6.5}>
          {content}
        </Stack>
      </Container>
    </Box>
  );
};

export default WithdrawalProgress;
