import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { useMutation } from 'react-query';
import { TransactionReceipt } from 'viem';
import { useAccount } from 'wagmi';

import EnsureNetworkButton from '../../components/EnsureNetworkButton';
import { useConfig } from '../../contexts/ConfigContext';
import { Side } from '../../core/type';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { usePublicL1Client } from '../../hooks/useStaticProvider';
import { useL1WalletClient, useL2WalletClient } from '../../hooks/useWalletClient';
import { useOutput } from './useOutput';

const FinalizeMessage: React.FC<{
  transactionHash?: string;
  onFinalized: () => void;
} & LoadingButtonProps> = ({
  transactionHash,
  onFinalized,
  ...props
}) => {
  const { address } = useAccount();
  const { l1 } = useConfig();
  const { alertSubmitted, alertConfirmed, alertFailed } = useAlertTransaction(Side.l1);
  const walletL1Client = useL1WalletClient();
  const walletL2Client = useL2WalletClient();
  const publicClientL1 = usePublicL1Client();
  const { withdrawal } = useOutput(transactionHash as `0x${string}` | undefined) || {};

  const { isLoading, mutate: proveMessage } = useMutation<string, any, any>({
    mutationFn: async (transactionHash: string) => {
      if (!address || !withdrawal) {
        throw new Error('Arguments missing');
      }

      const finalizeHash = await walletL1Client.finalizeWithdrawal({
        account: address,
        targetChain: walletL2Client.chain,
        withdrawal,
      });
      alertSubmitted(finalizeHash);
      const finalizeReceipt: TransactionReceipt = await publicClientL1.waitForTransactionReceipt({
        hash: finalizeHash,
      });

      return finalizeReceipt.transactionHash;
    },
    onSuccess(hash) {
      onFinalized();
      alertConfirmed(hash);
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  return (
    <EnsureNetworkButton targetChainId={l1.id} {...props}>
      <LoadingButton
        disabled={!address || !withdrawal}
        loading={isLoading}
        onClick={() => proveMessage(transactionHash)}
        variant="contained"
        {...props}
      >
        Finalize Message
      </LoadingButton>
    </EnsureNetworkButton>
  );
};

export default FinalizeMessage;
