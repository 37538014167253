import { createContext, useCallback, useContext, useRef, useState } from 'react';
import { usePublicL1Client, usePublicL2Client } from '../../hooks/useStaticProvider';
import { useParams } from 'react-router-dom';
import { getWithdrawals } from 'viem/op-stack';
import { portalAbi, l2OutputOracleAbi } from '../../core/abis';

interface Context {
  status: WithdrawalStatus;
  fetchStatus: () => Promise<void>;
  setStatus: (status: WithdrawalStatus) => void;
}

export const WithdrawalProgressContext = createContext<Context>({} as Context);

export const useWithdrawalProgress = () => {
  return useContext(WithdrawalProgressContext);
};

export enum WithdrawalStatus {
  UNKNOWN,
  STATE_ROOT_NOT_PUBLISHED,
  READY_TO_PROVE,
  IN_CHALLENGE_PERIOD,
  READY_FOR_RELAY,
  RELAYED,
}

export const WithdrawalProgressProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { transactionHash } = useParams<{ transactionHash: `0x${string}` }>();
  const publicClientL1 = usePublicL1Client();
  const publicClientL2 = usePublicL2Client();
  const [status, setStatus] = useState<WithdrawalStatus>(WithdrawalStatus.UNKNOWN);
  const lastStatusRef = useRef(status);

  const fetchStatus = useCallback(async () => {
    if (!transactionHash) {
      lastStatusRef.current = WithdrawalStatus.UNKNOWN;
      setStatus(lastStatusRef.current);
      return;
    }

    switch (lastStatusRef.current) {
      case WithdrawalStatus.RELAYED: {
        return;
      }
      case WithdrawalStatus.READY_FOR_RELAY: {
        const withdrawalHash = await getWithdrawalHash(transactionHash, publicClientL2);
        const finalized = await checkFinalized(withdrawalHash, publicClientL1);

        if (finalized) {
          lastStatusRef.current = WithdrawalStatus.RELAYED;
          setStatus(lastStatusRef.current);
          return;
        }

        lastStatusRef.current = WithdrawalStatus.READY_FOR_RELAY;
        setStatus(lastStatusRef.current);
        return;
      }
      case WithdrawalStatus.IN_CHALLENGE_PERIOD: {
        const withdrawalHash = await getWithdrawalHash(transactionHash, publicClientL2);
        const inChallengePeriod = await checkChallengePeriod(withdrawalHash, publicClientL1);

        if (inChallengePeriod) {
          return;
        }

        const finalized = await checkFinalized(withdrawalHash, publicClientL1);

        if (finalized) {
          lastStatusRef.current = WithdrawalStatus.RELAYED;
          setStatus(lastStatusRef.current);
          return;
        }

        lastStatusRef.current = WithdrawalStatus.READY_FOR_RELAY;
        setStatus(lastStatusRef.current);
        return;
      }
      case WithdrawalStatus.READY_TO_PROVE: {
        const withdrawalHash = await getWithdrawalHash(transactionHash, publicClientL2);
        const proven = await checkProven(withdrawalHash, publicClientL1);

        if (!proven) {
          return;
        }

        const inChallengePeriod = await checkChallengePeriod(withdrawalHash, publicClientL1);

        if (inChallengePeriod) {
          lastStatusRef.current = WithdrawalStatus.IN_CHALLENGE_PERIOD;
          setStatus(lastStatusRef.current);
          return;
        }

        const finalized = await checkFinalized(withdrawalHash, publicClientL1);

        if (finalized) {
          lastStatusRef.current = WithdrawalStatus.RELAYED;
          setStatus(lastStatusRef.current);
          return;
        }

        lastStatusRef.current = WithdrawalStatus.READY_FOR_RELAY;
        setStatus(lastStatusRef.current);
        return;

      }
      case WithdrawalStatus.UNKNOWN:
      case WithdrawalStatus.STATE_ROOT_NOT_PUBLISHED: {
        const published = await checkStatePublished(transactionHash, publicClientL1, publicClientL2);

        if (!published) {
          lastStatusRef.current = WithdrawalStatus.STATE_ROOT_NOT_PUBLISHED;
          setStatus(lastStatusRef.current);
          return;
        }

        const withdrawalHash = await getWithdrawalHash(transactionHash, publicClientL2);
        const proven = await checkProven(withdrawalHash, publicClientL1);

        if (!proven) {
          lastStatusRef.current = WithdrawalStatus.READY_TO_PROVE;
          setStatus(lastStatusRef.current);
          return;
        }

        const inChallengePeriod = await checkChallengePeriod(withdrawalHash, publicClientL1);

        if (inChallengePeriod) {
          lastStatusRef.current = WithdrawalStatus.IN_CHALLENGE_PERIOD;
          setStatus(lastStatusRef.current);
          return;
        }

        const finalized = await checkFinalized(withdrawalHash, publicClientL1);

        if (finalized) {
          lastStatusRef.current = WithdrawalStatus.RELAYED;
          setStatus(lastStatusRef.current);
          return;
        }

        lastStatusRef.current = WithdrawalStatus.READY_FOR_RELAY;
        setStatus(lastStatusRef.current);
        return;
      }
    }
  }, [publicClientL1, publicClientL2, transactionHash]);

  return (
    <WithdrawalProgressContext.Provider value={{ status, fetchStatus, setStatus }}>
      {children}
    </WithdrawalProgressContext.Provider>
  );
};

async function checkFinalized(withdrawalHash: string, publicClientL1: any) {
  return await publicClientL1.readContract({
    abi: portalAbi,
    address: publicClientL1.chain.OptimismPortalProxy,
    functionName: 'finalizedWithdrawals',
    args: [withdrawalHash],
  });
}

async function getWithdrawalHash(txHash: string, publicClientL2: any) {
  const receipt = await publicClientL2.waitForTransactionReceipt({ hash: txHash });
  const [{ withdrawalHash }] = getWithdrawals(receipt);

  return withdrawalHash;
}

async function checkChallengePeriod(withdrawalHash: string, publicClientL1: any) {
  const [[, proveTimestamp], period] = await Promise.all([
    publicClientL1.readContract({
      abi: portalAbi,
      address: publicClientL1.chain.OptimismPortalProxy,
      functionName: 'provenWithdrawals',
      args: [withdrawalHash],
    }),
    publicClientL1.readContract({
      abi: l2OutputOracleAbi,
      address: publicClientL1.chain.L2OutputOracleProxy,
      functionName: 'FINALIZATION_PERIOD_SECONDS',
    }),
  ]);

  return (proveTimestamp + period) * BigInt(1000) > Date.now();
}

async function checkProven(withdrawalHash: string, publicClientL1: any) {
  const withdrawal = await publicClientL1.readContract({
    abi: portalAbi,
    address: publicClientL1.chain.OptimismPortalProxy,
    functionName: 'provenWithdrawals',
    args: [withdrawalHash],
  });

  return withdrawal[1] !== BigInt(0);
}

async function checkStatePublished(txHash: string, publicClientL1: any, publicClientL2: any) { 
  const latestOutputIndex = await publicClientL1.readContract(
    {
      abi: l2OutputOracleAbi,
      address: publicClientL1.chain.L2OutputOracleProxy,
      functionName: 'latestOutputIndex',
    }
  );
  const [latestOutput, { blockNumber: l2BlockNumber }] = await Promise.all([
    publicClientL1.readContract({
      abi: l2OutputOracleAbi,
      address: publicClientL1.chain.L2OutputOracleProxy,
      functionName: 'getL2Output',
      args: [latestOutputIndex],
    }),
    publicClientL2.getTransaction({ hash: txHash }),
  ]);

  return latestOutput.l2BlockNumber >= l2BlockNumber;
}
