import { useMemo } from 'react';
import { createPublicClient, http } from 'viem';
import { publicActionsL1, publicActionsL2 } from 'viem/op-stack';

import { useConfig } from '../contexts/ConfigContext';

export const usePublicClient = (side: 'l1' | 'l2') => {
  const { l1, l2 } = useConfig();

  return useMemo(
    () =>
      side === 'l1'
        ? createPublicClient({
            chain: l1,
            transport: http(),
          })
        : createPublicClient({
            chain: l2,
            transport: http(),
          }),
    [l1, l2, side]
  );
};

export const usePublicL1Client = () => {
  const { l1 } = useConfig();

  return useMemo(
    () =>
      createPublicClient({
        chain: l1,
        transport: http(),
      }).extend(publicActionsL1()),
    [l1]
  );
};

export const usePublicL2Client = () => {
  const { l2 } = useConfig();

  return useMemo(
    () =>
      createPublicClient({
        chain: l2,
        transport: http(),
      }).extend(publicActionsL2()),
    [l2]
  );
};
