const RELAY_CONSTANT_OVERHEAD = BigInt(200_000)
const MIN_GAS_DYNAMIC_OVERHEAD_NUMERATOR = BigInt(64);
const MIN_GAS_DYNAMIC_OVERHEAD_DENOMINATOR = BigInt(63);
const MIN_GAS_CALLDATA_OVERHEAD = BigInt(16);
const RELAY_CALL_OVERHEAD = BigInt(40_000);
const RELAY_RESERVED_GAS = BigInt(40_000);
const RELAY_GAS_CHECK_BUFFER = BigInt(5_000);

export function baseGas(_minGasLimit: bigint = BigInt(200000)) {
  return RELAY_CONSTANT_OVERHEAD
        // Calldata overhead
        + BigInt(0) * MIN_GAS_CALLDATA_OVERHEAD
        // Dynamic overhead (EIP-150)
        + ((_minGasLimit * MIN_GAS_DYNAMIC_OVERHEAD_NUMERATOR) / MIN_GAS_DYNAMIC_OVERHEAD_DENOMINATOR)
        // Gas reserved for the worst-case cost of 3/5 of the `CALL` opcode's dynamic gas
        // factors. (Conservative)
        + RELAY_CALL_OVERHEAD   
        // Relay reserved gas (to ensure execution of `relayMessage` completes after the
        // subcontext finishes executing) (Conservative)
        + RELAY_RESERVED_GAS
        // Gas reserved for the execution between the `hasMinGas` check and the `CALL`
        // opcode. (Conservative)
        + RELAY_GAS_CHECK_BUFFER;
}