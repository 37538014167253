import { Typography } from '@mui/material';
import ProveMessage from '../ProveMessage';
import { WithdrawalStatus, useWithdrawalProgress } from '../WithdrawalProgressContext';

export function ReadyToProve({ transactionHash }: { transactionHash?: string }) {
  const { setStatus } = useWithdrawalProgress();

  return (
    <>
      <Typography mb={2.5} variant="body2">
        Ready for prove
      </Typography>
      <ProveMessage onProven={() => setStatus(WithdrawalStatus.IN_CHALLENGE_PERIOD)} transactionHash={transactionHash} />
    </>
  );
}
