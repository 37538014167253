import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import { useMutation } from 'react-query';
import { useAccount } from 'wagmi';

import { BridgeToken, useConfig } from '../../contexts/ConfigContext';
import { addWithdraw } from '../../core/storage/withdraw';
import { Side } from '../../core/type';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { useReservedParamsNavigate } from '../../hooks/useReservedParamsNavigate';
import { usePublicL2Client } from '../../hooks/useStaticProvider';
import { useL2WalletClient } from '../../hooks/useWalletClient';

const WithdrawERC20Button: React.FC<{
  valid?: boolean;
  amount: bigint;
  token: BridgeToken;
}> = ({ amount, token, valid }) => {
  const { address } = useAccount();
  const {
    l2: {
      contracts: { l2StandardBridge },
    },
  } = useConfig();
  const navigate = useReservedParamsNavigate();
  const { alertSubmitted, alertConfirmed, alertFailed } = useAlertTransaction(Side.l2);
  const walletClient = useL2WalletClient();
  const publicL2Client = usePublicL2Client();
  const { isLoading, mutate: withdrawERC20 } = useMutation<string, any, void>({
    mutationFn: async () => {
      if (!address) {
        throw new Error('Arguments missing');
      }

      const hash = await walletClient.writeContract({
        address: l2StandardBridge.address,
        abi,
        functionName: 'bridgeERC20',
        args: [token.address, token.oppositeToken.address, amount, BigInt('21000'), '0x'],
        account: address,
      });
      addWithdraw({
        amountOrTokenId: amount,
        tokenAddress: token.address,
        account: address,
        transactionHash: hash,
      });
      alertSubmitted(hash);
      await publicL2Client.waitForTransactionReceipt({ hash });

      return hash;
    },
    onSuccess(hash) {
      alertConfirmed(hash);
      navigate(`/withdraw/progress/${hash}`, { id: true });
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  return (
    <LoadingButton
      disabled={!valid}
      fullWidth
      loading={isLoading}
      onClick={() => withdrawERC20()}
      variant="contained"
    >
      Withdraw
    </LoadingButton>
  );
};

export default WithdrawERC20Button;

const abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_localToken',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_remoteToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
      {
        internalType: 'uint32',
        name: '_minGasLimit',
        type: 'uint32',
      },
      {
        internalType: 'bytes',
        name: '_extraData',
        type: 'bytes',
      },
    ],
    name: 'bridgeERC20',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
