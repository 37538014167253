import { Typography } from '@mui/material';
import IconWithdrawInitiated from '../../../components/icons/IconWithdrawInitiated';
import { usePoll } from '../../../hooks/usePoll';
import { useWithdrawalProgress } from '../WithdrawalProgressContext';

export function StateNotPublished() {
  const { fetchStatus } = useWithdrawalProgress();

  usePoll(fetchStatus, window.appConfig.rpcPollingInterval || (45 * 1000));

  return (
    <>
      <IconWithdrawInitiated sx={{ width: '48px', height: 'auto' }} />
      <Typography mt={2.5} variant="h6">
        Waiting for state root to be published
      </Typography>
    </>
  );
}
