import { Box, SxProps } from '@mui/material';
import React from 'react';

import IconAltLayer from './icons/chains/IconAltLayer';
import IconEth from './icons/chains/IconEth';
import { useConfig } from '../contexts/ConfigContext';

const ethChainIds = [1, 5, 11155111];

const ChainLogo: React.FC<{ chainId: number; sx?: SxProps }> = ({ chainId, sx: _sx }) => {
  const { l1, l2 } = useConfig();
  const sx = {
    width: (_sx as any)?.width || 20,
    height: (_sx as any)?.height || 20,
    ..._sx,
  }
  if (ethChainIds.includes(chainId)) {
    return <IconEth sx={sx} />;
  }

  if (l1.id === chainId) {
    return <Box component="img" src={l1.iconUrl} sx={sx} />;
  }

  if (l2.id === chainId) {
    return <Box component="img" src={l2.iconUrl} sx={sx} />;
  }

  return <IconAltLayer sx={sx} />;
};

export default ChainLogo;
