import IconLoadCircle from '../../../components/icons/IconLoadCircle';
import { usePoll } from '../../../hooks/usePoll';
import { useWithdrawalProgress } from '../WithdrawalProgressContext';

export function Unknown() {
  const { fetchStatus } = useWithdrawalProgress();

  usePoll(fetchStatus, window.appConfig.rpcPollingInterval || (45 * 1000));

  return (
    <IconLoadCircle
      sx={{
        animation: 'rotation 1.5s infinite linear',
        width: '48px',
        height: 'auto',
      }}
    />
  );
}
