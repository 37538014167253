import { Box, CssBaseline } from '@mui/material';
import { Navigate, useRoutes } from 'react-router-dom';

import Layout from './components/Layout';
import { useReservedParams } from './hooks/useReservedParams';
import ChainSelection from './pages/ChainSelection';
import Deposit from './pages/Deposit';
import DepositProgress from './pages/DepositProgress';
import Disclaimer from './pages/Legal/Disclaimer';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TransactionHistory from './pages/TransactionHistory';
import Withdraw from './pages/Withdraw';
import WithdrawalProgress from './pages/WithdrawalProgress';
import { inputGlobalStyles } from './theme/globalStyle';
import { WithdrawalProgressProvider } from './pages/WithdrawalProgress/WithdrawalProgressContext';

const DefaultRoute: React.FC = () => {
  const queryParams = useReservedParams({ id: true });

  return <Navigate to={`/deposit${queryParams}`} />;
};

const routes = [
  {
    element: <DefaultRoute />,
    path: '*',
  },
  {
    element: <ChainSelection />,
    path: 'chain-selection',
  },
  {
    element: <TransactionHistory />,
    path: '/transactions',
  },
  {
    path: 'deposit',
    children: [
      {
        path: '',
        element: (
          <Box
            sx={{
              mt: 10,
              flexBasis: '552px',
            }}
          >
            <Deposit />
          </Box>
        ),
      },
      {
        path: 'progress/:transactionHash',
        element: (
          <Box
            sx={{
              mt: 10,
              flexBasis: '552px',
            }}
          >
            <DepositProgress />
          </Box>
        ),
      },
    ],
  },
  {
    path: 'withdraw',
    children: [
      {
        element: (
          <Box
            sx={{
              mt: 10,
              flexBasis: '552px',
            }}
          >
            <Withdraw />
          </Box>
        ),
        path: '',
      },
      {
        element: (
          <Box
            sx={{
              mt: 10,
              flexBasis: '552px',
            }}
          >
            <WithdrawalProgressProvider>
              <WithdrawalProgress />
            </WithdrawalProgressProvider>
          </Box>
        ),
        path: '/withdraw/progress/:transactionHash',
      },
    ],
  },

  {
    element: (
      <Box
        sx={{
          overflow: 'scroll',
        }}
      >
        <PrivacyPolicy />
      </Box>
    ),
    path: '/privacy-policy',
  },
  {
    element: (
      <Box
        sx={{
          overflow: 'scroll',
        }}
      >
        <Disclaimer />
      </Box>
    ),
    path: '/legal-disclaimer',
  },
];

const App: React.FC = () => {
  const content = useRoutes(routes);

  return (
    <>
      <CssBaseline />
      {inputGlobalStyles}
      <Layout>{content}</Layout>
    </>
  );
};

export default App;
