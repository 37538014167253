import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

import { BridgeToken, TokenType } from '../contexts/ConfigContext';
import { OwnerOf } from '../core/abis';
import { Side } from '../core/type';
import { usePublicClient } from './useStaticProvider';

export const useTokenOwned = (
  tokenId: string,
  side: Side,
  token: BridgeToken | null
): boolean | undefined => {
  const [owned, setOwned] = useState<boolean>();
  const account = useAccount();
  const pulicClient = usePublicClient(side);

  useEffect(() => {
    const tokenIdInt = parseInt(tokenId);

    if (
      !token ||
      token.type !== TokenType.ERC721 ||
      isNaN(tokenIdInt) ||
      !account.isConnected ||
      !account.address
    ) {
      setOwned(undefined);

      return;
    }

    pulicClient
      .readContract({
        abi: [OwnerOf],
        args: [tokenIdInt],
        address: token.address,
        functionName: 'OwnerOf',
      })
      .then(owner => {
        setOwned((owner as string) === account.address);
      })
      .catch((e) => {
        console.error(e);
        setOwned(false);
      });
  }, [account.address, account.isConnected, pulicClient, token, tokenId]);

  return owned;
};
