import { useEffect, useState } from 'react';

import { usePublicL1Client, usePublicL2Client } from './useStaticProvider';
import { getL2TransactionHashes } from 'viem/op-stack';
import { usePeriodicalSignal } from './usePeriodically';

export enum DepositStatus {
  UNKNOWN,
  FAILED_L1_TO_L2_MESSAGE,
  INITIATED,
  RELAYED,
}

export const useDepositStatus = (txHash: string | undefined): DepositStatus => {
  const [status, setStatus] = useState(DepositStatus.UNKNOWN);
  const publicClientL1 = usePublicL1Client();
  const publicClientL2 = usePublicL2Client();
  const {
    signal,
    stop$,
  } = usePeriodicalSignal(window.appConfig.rpcPollingInterval || 45 * 1000, window.appConfig.rpcPollingInterval || 45 * 1000);

  useEffect(() => {
    async function fetch() {
      if (!txHash) {
        return;
      }

      try {
        const receipt = await publicClientL1.waitForTransactionReceipt({ hash: txHash as  `0x${string}` })
        setStatus(DepositStatus.INITIATED);
        const [l2Hash] = getL2TransactionHashes(receipt)

        await publicClientL2.waitForTransactionReceipt({
          hash: l2Hash
        });
        setStatus(DepositStatus.RELAYED);
      } catch (e) {
        console.error(e);
        setStatus(DepositStatus.UNKNOWN);
      }
    }

    fetch();

    return () => {
      stop$.next();
    }
  }, [publicClientL1, stop$, txHash, signal, publicClientL2]);

  return status;
};
