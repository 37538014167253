import { useMemo } from 'react';
import { createWalletClient, custom } from 'viem';
import { walletActionsL1, walletActionsL2 } from 'viem/op-stack';
import { useAccount } from 'wagmi';

import { useConfig } from '../contexts/ConfigContext';

export function useL1WalletClient() {
  const { address } = useAccount();
  const { l1 } = useConfig();

  return useMemo(
    () =>
      createWalletClient({
        account: address,
        chain: l1,
        transport: custom(window.ethereum),
      }).extend(walletActionsL1()),
    [address, l1]
  );
}

export function useL2WalletClient() {
  const { address } = useAccount();
  const { l2 } = useConfig();

  return useMemo(
    () =>
      createWalletClient({
        account: address,
        chain: l2,
        transport: custom(window.ethereum),
      }).extend(walletActionsL2()),
    [address, l2]
  );
}
