import { darkTheme, getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useMemo } from 'react';
import { WagmiProvider } from 'wagmi';
import { Chain, goerli } from 'wagmi/chains';

import { useConfig } from './ConfigContext';

const appName = 'OP Stack Bridge By AltLayer';
const projectId = window.appConfig?.walletConnectProjectId || '';
const theme = darkTheme();

theme.colors.accentColor = '#6667ab';
theme.colors.profileForeground = '#373741';
theme.colors.modalBackground = '#373741';
theme.radii.modal = '16px';

export const WagmiWalletProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { l1, l2 } = useConfig();
  const config = useMemo(() => {
    const chains: [Chain, ...Chain[]] = l1 && l2 ? [l1, l2] : [goerli];

    return getDefaultConfig({
      appName,
      projectId,
      chains,
    })
  } , [l1, l2]);
  const queryClient = new QueryClient();

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={theme}>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
