import { WithdrawalStatus, useWithdrawalProgress } from '../WithdrawalProgressContext';
import IconWithdrawable from '../../../components/icons/IconWithdrawable';
import FinalizeMessage from '../FinalizeMessage';

export function ReadyToFinalize({ transactionHash }: { transactionHash?: string }) {
  const { setStatus } = useWithdrawalProgress();

  return (
    <>
      <IconWithdrawable sx={{ width: '48px', height: 'auto' }} />
      <FinalizeMessage onFinalized={() => setStatus(WithdrawalStatus.RELAYED)} sx={{ mt: 2.5 }} transactionHash={transactionHash} />
    </>
  );
}
