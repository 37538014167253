export * from './l2OutputOracle';
export * from './portal';
export * from './l2ToL1MessagePasser';

export { default as ERC20Abi } from './ERC20.json';
export { default as ApprovalAbi } from './Approval.json';

export const OwnerOf = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'tokenId',
      type: 'uint256',
    },
  ],
  name: 'ownerOf',
  outputs: [
    {
      internalType: 'address',
      name: '',
      type: 'address',
    },
  ],
  stateMutability: 'view',
  type: 'function',
};
export const ERC20BalanceOf = {
  inputs: [
    {
      internalType: 'address',
      name: 'account',
      type: 'address',
    },
  ],
  name: 'balanceOf',
  outputs: [
    {
      internalType: 'uint256',
      name: '',
      type: 'uint256',
    },
  ],
  stateMutability: 'view',
  type: 'function',
};
