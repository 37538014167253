import { useEffect, useMemo, useState } from 'react';
import { Observable, Subject, from, mergeMap, of } from 'rxjs';
import { catchError, delay, takeUntil } from 'rxjs';

type Millisecond = number;

export const usePoll = (fn: () => Promise<unknown>, intervalDuration: Millisecond) => {
  const [stop$] = useState(new Subject<void>());

  useEffect(() => {
    function poll(): Observable<unknown> {
      return of(true).pipe(
        takeUntil(stop$),
        mergeMap(() => from(fn())),
        delay(intervalDuration),
        mergeMap(() => poll()),
        catchError(e => {
          console.error(e);

          return of();
        }),
      );
    }

    const sub = poll().subscribe();

    return () => {
      sub.unsubscribe();
    }
  }, [fn, intervalDuration, stop$]);

  return useMemo(
    () => ({
      stop$,
    }),
    [stop$]
  );
};
