import { useEffect, useState } from 'react';
import { usePublicL1Client, usePublicL2Client } from '../../hooks/useStaticProvider';
import { l2OutputOracleAbi } from '../../core/abis';
import { getWithdrawals } from 'viem/op-stack';
import { TransactionReceipt } from 'viem';

export function useOutput(txHash?: `0x${string}`) {
  const [output, setOutput] = useState <
    {
      output: {
        outputIndex: bigint;
        outputRoot: `0x${string}`;
        timestamp: bigint;
        l2BlockNumber: bigint;
      };
      withdrawal: any;
    } | undefined
  >();
  const publicClientL2 = usePublicL2Client();
  const publicClientL1 = usePublicL1Client();
  const l2OutputOracleAddress = publicClientL1.chain.L2OutputOracleProxy;

  useEffect(() => {
    async function fetch() {
      if (!txHash) {
        return;
      }
      const receipt: TransactionReceipt = await publicClientL2.waitForTransactionReceipt({ hash: txHash });
      const [withdrawal] = getWithdrawals(receipt);
      const outputIndex = await publicClientL1.readContract({
        address: l2OutputOracleAddress,
        abi: l2OutputOracleAbi,
        functionName: 'getL2OutputIndexAfter',
        args: [receipt.blockNumber],
      });
      const output = await publicClientL1.readContract({
        abi: l2OutputOracleAbi,
        address: publicClientL1.chain.L2OutputOracleProxy,
        functionName: 'getL2Output',
        args: [outputIndex],
      });

      setOutput({ output: { outputIndex, ...output }, withdrawal});
    }

    fetch();
  }, [l2OutputOracleAddress, publicClientL1, publicClientL2, txHash]);

  return output;
}
