import { useEffect, useState } from 'react';

import { Deposit, getStorageKey } from '../core/storage/deposit';

export function useDepositList(signal: number): Deposit[] {
  const [deposits, setDeposits] = useState<Deposit[]>([]);

  useEffect(() => {
    try {
      const deposits = JSON.parse(localStorage.getItem(getStorageKey('deposit')) || '[]');

      setDeposits(deposits);
    } catch (e) {
      console.error(e);
      localStorage.setItem(getStorageKey('deposit'), JSON.stringify([]));
      setDeposits([]);
    }
  }, [signal]);

  return deposits;
}
