import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import { TransactionReceipt } from 'viem';
import { useAccount } from 'wagmi';

import EnsureNetworkButton from '../../components/EnsureNetworkButton';
import { useConfig } from '../../contexts/ConfigContext';
import { Side } from '../../core/type';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { usePublicL1Client, usePublicL2Client } from '../../hooks/useStaticProvider';
import { useL1WalletClient } from '../../hooks/useWalletClient';
import { useOutput } from './useOutput';

const ProveMessage: React.FC<{
  transactionHash?: string;
  onProven: () => void;
}> = ({ transactionHash, onProven }) => {
  const { l1 } = useConfig();
  const { address } = useAccount();
  const { alertSubmitted, alertConfirmed, alertFailed } = useAlertTransaction(Side.l1);
  const walletL1Client = useL1WalletClient();
  const publicClientL1 = usePublicL1Client();
  const publicClientL2 = usePublicL2Client();
  const { output, withdrawal } = useOutput(transactionHash as `0x${string}` | undefined) || {};

  const { isLoading, mutate: proveMessage } = useMutation<string, any, any>({
    mutationFn: async () => {
      if (!address || !output || !withdrawal) {
        throw new Error('Arguments missing');
      }

      const proveArgs = await publicClientL2.buildProveWithdrawal({
        account: address,
        output,
        withdrawal,
      });
      const proveHash = await walletL1Client.proveWithdrawal(proveArgs);
      alertSubmitted(proveHash);
      const proveReceipt: TransactionReceipt = await publicClientL1.waitForTransactionReceipt({
        hash: proveHash,
      });

      return proveReceipt.transactionHash;
    },
    onSuccess(hash) {
      onProven();
      alertConfirmed(hash);
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  return (
    <EnsureNetworkButton targetChainId={l1.id}>
      <LoadingButton
        disabled={!address || !output || !withdrawal}
        loading={isLoading}
        onClick={() => proveMessage(transactionHash)}
        variant="contained"
      >
        Prove Message
      </LoadingButton>
    </EnsureNetworkButton>
  );
};

export default ProveMessage;
