import { Typography } from '@mui/material';
import IconCircleChecked from '../../../components/icons/IconCircleChecked';

export function Finalized() {
  return <>
    <IconCircleChecked sx={{ width: '48px', height: 'auto' }} />
    <Typography mt={2.5} variant="h6">
      Completed
    </Typography>
  </>
}
