import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Stack, useTheme } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSwitchChain } from 'wagmi';

import { useConfig } from '../contexts/ConfigContext';
import { useReservedParamsNavigate } from '../hooks/useReservedParamsNavigate';

const SwitchTransferDirection: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useReservedParamsNavigate();
  const { switchChain } = useSwitchChain();
  const { l1, l2 } = useConfig();
  const theme = useTheme();

  return (
    <Stack flexDirection="row" justifyContent="center" pb={1} pt={0.75}>
      <ArrowDownwardIcon
        onClick={() => {
          const currentIsDeposit = pathname.includes('/deposit');

          currentIsDeposit
            ? navigate('/withdraw', { token: true, id: true })
            : navigate('/deposit', { token: true, id: true });
          switchChain({ chainId: currentIsDeposit ? l2.id : l1.id });
        }}
        sx={{
          color: 'white',
          background: theme.colors.functional.container.dark,
          height: '40px',
          width: '40px',
          borderRadius: '50%',
          padding: '7px',
          cursor: 'pointer',
          ':hover': {
            background: theme.colors.functional.container.primary,
            color: theme.colors.schema.secondary,
          },
        }}
      />
    </Stack>
  );
};

export default SwitchTransferDirection;
