import { formatUnits, parseUnits } from 'viem/utils';

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export function formatDisplayUnits(
  balance: bigint | string,
  decimals = 18,
  displayUnit = 3
): string {
  const _balance = typeof balance === 'string' ? BigInt(balance) : balance;

  // Always return a '0' if it is exactly 0.
  if (_balance === BigInt(0)) return '0';

  const value = formatUnits(_balance, decimals);
  let [integral, fractional] = value.split('.');
  fractional = fractional || '';


  // further logic here to round a number
  if (decimals === 0 || displayUnit === 0) return integral;

  const displayFraction =
    fractional.length < displayUnit
      ? `${fractional + '0'.repeat(displayUnit - fractional.length)}`
      : fractional.length > displayUnit
      ? fractional.slice(0, displayUnit)
      : fractional;

  return trimZero(`${integral}.${displayFraction}`);
}

function trimZero(v: string) {
  const regexp = /(?:\.0*|(\.\d+?)0+)$/;

  return v.replace(regexp, '$1');
}

export function toFixed(n: string, decimals: number): string {
  const s = `${n}`.split('.');

  if (!s[1]) {
    return [s[0], '.'].concat(new Array(decimals).fill('0')).join('');
  }

  if (s[1].length < decimals) {
    return [s[0], '.', s[1]].concat(new Array(decimals - s[1].length).fill('0')).join('');
  }

  if (s[1].length > decimals) {
    return [s[0], '.'].concat(s[1].slice(0, decimals)).join('');
  }

  return `${n}`;
}

export function parseUnitsSafe(n: string, decimals: number): bigint {
  return parseUnits(toFixed(n, decimals), decimals);
}

export const getKey = (() => {
  const MIN = 0;
  const MAX = 100000;
  let index = MIN;
  const rounds: number[] = [0];

  return () => {
    if (index + 1 > MAX) {
      index = MIN;
      const lastRoundIndex = rounds.findIndex(round => round < 1000);

      if (lastRoundIndex < 0) {
        rounds.push(0);
      } else {
        rounds[lastRoundIndex] += 1;
      }
    }

    return `${++index}|${rounds.join('|')}`;
  };
})();

export function isNonnegativeNumber(target: string): boolean {
  return /^\d+(\.{0,1}\d+){0,1}$/.test(target);
}

export function getTimestampIsoString(timestamp?: number) {
  return new Date(timestamp ? timestamp * 1000 : new Date().getTime()).toISOString();
}

export async function asyncTryCatch<T>(
  func: (...args: any[]) => Promise<T>,
  ...args: any[]
): Promise<[Error, undefined] | [undefined, T]> {
  try {
    const value: T = await func(...args);

    return [undefined, value];
  } catch (e: any) {
    return [e, undefined];
  }
}

export async function sleep(timeout: number) {
  await new Promise(resolve => {
    setTimeout(() => {
      resolve(undefined);
    }, timeout);
  });
}
