import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import { useMutation } from 'react-query';
import { useAccount } from 'wagmi';

import { BridgeToken } from '../../contexts/ConfigContext';
import { addDeposit } from '../../core/storage/deposit';
import { Side } from '../../core/type';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { useReservedParamsNavigate } from '../../hooks/useReservedParamsNavigate';
import { usePublicL1Client, usePublicL2Client } from '../../hooks/useStaticProvider';
import { useL1WalletClient } from '../../hooks/useWalletClient';
import { baseGas } from '../../core/baseGas';

const DepositNativeButton: React.FC<{
  amount: bigint;
  token: BridgeToken;
  valid?: boolean;
}> = ({ amount, token, valid }) => {
  const { address } = useAccount();
  const { alertSubmitted, alertConfirmed, alertFailed } = useAlertTransaction(Side.l1);
  const navigate = useReservedParamsNavigate();
  const walletClient = useL1WalletClient();
  const publicL1Client = usePublicL1Client();
  const publicL2Client = usePublicL2Client();

  const { isLoading, mutate: depositNative } = useMutation<string, any, void>({
    mutationFn: async () => {
      if (!address) {
        throw new Error('Arguments miss');
      }

      const args = await publicL2Client.buildDepositTransaction({
        account: address,
        mint: amount,
        to: address,
        gas: baseGas(), // https://github.com/wevm/viem/issues/1903
      });
      const hash = await walletClient.depositTransaction(args);

      addDeposit({
        amountOrTokenId: amount,
        tokenAddress: token.address,
        account: address,
        transactionHash: hash,
      });
      alertSubmitted(hash);
      await publicL1Client.waitForTransactionReceipt({ hash });

      return hash;
    },
    onSuccess(hash) {
      alertConfirmed(hash);
      navigate(`/deposit/progress/${hash}`, { id: true });
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  return (
    <LoadingButton
      disabled={!valid}
      fullWidth
      loading={isLoading}
      onClick={() => depositNative()}
      variant="contained"
    >
      Deposit
    </LoadingButton>
  );
};

export default DepositNativeButton;
