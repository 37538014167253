import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import { useMutation } from 'react-query';
import { useAccount } from 'wagmi';

import { BridgeToken } from '../../contexts/ConfigContext';
import { addWithdraw } from '../../core/storage/withdraw';
import { Side } from '../../core/type';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { useReservedParamsNavigate } from '../../hooks/useReservedParamsNavigate';
import { usePublicL1Client, usePublicL2Client } from '../../hooks/useStaticProvider';
import { useL2WalletClient } from '../../hooks/useWalletClient';
import { baseGas } from '../../core/baseGas';

const WithdrawNativeButton: React.FC<{
  amount: bigint;
  token: BridgeToken;
  valid?: boolean;
}> = ({ amount, token, valid }) => {
  const { address } = useAccount();
  const walletClient = useL2WalletClient();
  const publicClientL2 = usePublicL2Client();
  const publicClientL1 = usePublicL1Client();
  const navigate = useReservedParamsNavigate();
  const { alertSubmitted, alertConfirmed, alertFailed } = useAlertTransaction(Side.l2);
  const { isLoading, mutate: withdrawNative } = useMutation<string, any, void>({
    mutationFn: async () => {
      if (!address) {
        throw new Error('Arguments missing');
      }

      const args = await publicClientL1.buildInitiateWithdrawal({
        account: address,
        to: address,
        value: amount,
        gas: baseGas() // https://github.com/wevm/viem/issues/1903
      });

      const hash = await walletClient.initiateWithdrawal(args);
      addWithdraw({
        amountOrTokenId: amount,
        tokenAddress: token.address,
        account: address,
        transactionHash: hash,
      });
      alertSubmitted(hash);
      await publicClientL2.waitForTransactionReceipt({ hash });

      return hash;
    },
    onSuccess(hash) {
      alertConfirmed(hash);
      navigate(`/withdraw/progress/${hash}`, { id: true });
    },
    onError(e: any) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  return (
    <LoadingButton
      disabled={!valid}
      fullWidth
      loading={isLoading}
      onClick={() => withdrawNative()}
      variant="contained"
    >
      Withdraw
    </LoadingButton>
  );
};

export default WithdrawNativeButton;
