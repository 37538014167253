import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import IconCircleChecked from '../../components/icons/IconCircleChecked';
import IconLoadCircle from '../../components/icons/IconLoadCircle';
import IconNotFound from '../../components/icons/IconNotFound';
import ProgressStepper from '../../components/ProgressStepper';
import TransferAmount from '../../components/TransferAmount';
import TransferDirection from '../../components/TransferDirection';
import { useConfig } from '../../contexts/ConfigContext';
import { Side } from '../../core/type';
import useDepositInLS from '../../hooks/useDeposit';
import { DepositStatus, useDepositStatus } from '../../hooks/useDepositStatus';
import { useReservedParams } from '../../hooks/useReservedParams';
import { TxnHistoryTab } from '../TransactionHistory';

const steps = [DepositStatus.INITIATED, DepositStatus.RELAYED];

const DepositProgressContent: FC<{ statusText: string; Icon: ReactNode }> = ({
  Icon,
  statusText,
}) => {
  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
      minHeight="10rem"
      spacing={2}
    >
      <Grid item>{Icon}</Grid>
      <Grid item>
        <Typography sx={{ color: '#fff' }}>{statusText}</Typography>
      </Grid>
    </Grid>
  );
};

const DepositProgress: React.FC = () => {
  const { transactionHash } = useParams<{ transactionHash: string }>();
  const status = useDepositStatus(transactionHash);
  const deposit = useDepositInLS(transactionHash);
  const { l1Tokens } = useConfig();
  const token = useMemo(
    () => l1Tokens.find(token => token.address === deposit?.tokenAddress),
    [deposit?.tokenAddress, l1Tokens]
  );
  const theme = useTheme();
  const activeStep = steps.findIndex(cur => cur === status);
  const queryParams = useReservedParams({ id: true });

  const content = useMemo(() => {
    if (!deposit) {
      return (
        <DepositProgressContent
          Icon={
            <IconNotFound
              sx={{
                width: '48px',
                height: 'auto',
              }}
            />
          }
          statusText="Sorry, no transaction details were found by giving transaction hash"
        />
      );
    }

    if (status === DepositStatus.UNKNOWN) {
      return (
        <DepositProgressContent
          Icon={
            <IconLoadCircle
              sx={{
                animation: 'rotation 1.5s infinite linear',
                width: '48px',
                height: 'auto',
              }}
            />
          }
          statusText=""
        />
      );
    } else if (status === DepositStatus.INITIATED) {
      return (
        <DepositProgressContent
          Icon={<CurrencyExchangeIcon sx={{ width: 60, height: 60, fill: '#6667AB' }} />}
          statusText="Deposit initiated"
        />
      );
    } else if (status === DepositStatus.RELAYED) {
      return (
        <>
          <DepositProgressContent
            Icon={<IconCircleChecked sx={{ width: 60, height: 60 }} />}
            statusText="Deposit finalized"
          />
          <Button
            component={NavLink}
            fullWidth
            sx={{ textTransform: 'none' }}
            to={`/deposit${queryParams}`}
            variant="contained"
          >
            Back to home
          </Button>
        </>
      );
    } else if (status === DepositStatus.FAILED_L1_TO_L2_MESSAGE) {
      return (
        <DepositProgressContent
          Icon={
            <IconNotFound
              sx={{
                height: '80px',
                width: '80px',
              }}
            />
          }
          statusText="Transaction failed"
        />
      );
    }
  }, [deposit, queryParams, status]);

  return (
    <Box>
      <Breadcrumbs
        links={[
          { href: '/', name: 'home' },
          {
            href: '/transactions',
            name: 'transactions',
            params: {
              type: TxnHistoryTab.Deposit,
            },
          },
          { name: 'deposit progress' },
        ]}
      />
      <Container
        sx={{
          background: theme.colors.functional.container.default,
          borderRadius: '4px',
          py: 3,
          my: 1,
        }}
      >
        <Typography variant="h5">Deposit progress</Typography>
        <TransferDirection from={Side.l1} />
        {token && deposit && (
          <TransferAmount amount={deposit.amountOrTokenId} from={Side.l1} mb={5} token={token} />
        )}
        {status !== DepositStatus.UNKNOWN && status !== DepositStatus.FAILED_L1_TO_L2_MESSAGE && (
          <ProgressStepper activeStep={activeStep} steps={steps} />
        )}
        {content}
      </Container>
    </Box>
  );
};

export default DepositProgress;
