import { getStorageKey } from './deposit';

export const WITHDRAW_KEY = 'withdraw-list';

export interface Withdraw {
  transactionHash: string;
  from: string;
  amountOrTokenId: string;
  // for indexing token
  tokenAddress: string;
  timestamp: string;
}

interface IAddWithdraw {
  amountOrTokenId: bigint;
  tokenAddress: string;
  account: string;
  transactionHash: string;
}

export function saveWithdrawToStorage(withdraw: Withdraw) {
  const data = localStorage.getItem(getStorageKey('withdrawal'));
  let withdrawList: Withdraw[] = [];

  try {
    withdrawList = data ? JSON.parse(data) : [];
  } catch (e) {
    console.debug(e);
  }

  const existingIndex = withdrawList.findIndex(
    _withdraw =>
      _withdraw.transactionHash &&
      withdraw.transactionHash &&
      _withdraw.transactionHash === withdraw.transactionHash
  );

  if (existingIndex < 0) {
    withdrawList.push(withdraw);
  } else {
    withdrawList.splice(existingIndex, 1, withdraw);
  }

  localStorage.setItem(getStorageKey('withdrawal'), JSON.stringify(withdrawList));
}

export function addWithdraw({
  account,
  amountOrTokenId,
  tokenAddress,
  transactionHash,
}: IAddWithdraw) {
  const withdraw: Withdraw = {
    transactionHash,
    from: account,
    amountOrTokenId: amountOrTokenId.toString(),
    tokenAddress,
    timestamp: new Date().toISOString(),
  };

  saveWithdrawToStorage(withdraw);
}

export function getWithdrawByTransactionHash(transactionHash: string) {
  const data = localStorage.getItem(getStorageKey('withdrawal'));
  let withdrawList: Withdraw[] = [];

  try {
    withdrawList = data ? JSON.parse(data) : [];
  } catch (e) {
    console.error(e);
  }

  const withdrawal = withdrawList.find(withdraw => withdraw.transactionHash === transactionHash);

  return withdrawal;
}
